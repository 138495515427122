import React from "react";
import LazyLoad from "react-lazyload";
import linkedinIcon from "../../../images/mlp/linkedin-icon.png";

const video = (data) => {
  return (
    data.video && (
      <div className="card-video">
        <LazyLoad>
          <iframe
            width="100%"
            height={200}
            src={data.video}
            title="Bitcoin Bottom Price Prediction | BTC $ 21500"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            className="video"
          ></iframe>
        </LazyLoad>
      </div>
    )
  );
};

const MasonaryCard = ({ data }) => {
  return (
    <div className="MasonaryCard">
      {video(data)}
      {data.name && (
        <div className="card-desc">
          <div className="card-head">
            {data.linkedin && (
              <div>
                <a href={data.linkedin}>
                  <img className="linkedin-icon" src={linkedinIcon} />
                </a>
              </div>
            )}
          </div>
          {data.name && <div className="name">{data.name}</div>}
          {data.position && <div className="position">{data.position}</div>}
          {data.about && (
            <div
              className="about"
              dangerouslySetInnerHTML={{ __html: data.about }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MasonaryCard;
