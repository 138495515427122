import React from "react";
import Seo from "../components/Seo/seo";
import Layout from "../components/Layout/layout";
import HeroSection from "../components/Homepage/HeroSection";
import PickProgram from "../components/Homepage/PickProgram/pickProgram";
import KraftshalaWorks from "../components/Homepage/KraftshalaWorks/kraftshalaWorks";

import "./styles.scss";
import TopCompanies from "../components/Homepage/TopCompanies/topCompanies";
import StudentsBridge from "../components/Homepage/StudentsBridgeGap";
import Recruiters from "../components/Homepage/recruiters/Recruiters";
import Feeds from "../components/Homepage/feeds/Feeds";
import NewsCoverage from "../components/Homepage/News";
import logo from "../../static/assets/nav_logo.webp";
import ContactUsPopup from "../components/ContactUsPopup";
import KSLiveBanner from "../components/Homepage/KSLiveBanner";

export default function Home(props) {

  const homeSnippt = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: "https://www.kraftshala.com/",
    logo:
      "https://kfwebassets.s3.ap-south-1.amazonaws.com/Kraftshala-Social.png",
  };

  const courseSnippt = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        url:
          "https://www.kraftshala.com/marketing-launchpad/digital-marketing-course/",
      },
      {
        "@type": "ListItem",
        position: 2,
        url:
          "https://www.kraftshala.com/professional-brand-management-in-the-digital-age/",
      },
    ],
  };

  return (
    <div className="main-homepage">
      <Seo
        title="Kraftshala - Best Job-linked Online Course Platform in India"
        description=" Kraftshala makes India’s most loved online courses for students & professionals that are 100% industry-driven. Be a part of India’s most promising talent today"
        url="https://www.kraftshala.com/"
        homeSnipptJSON={JSON.stringify(homeSnippt)}
        courseSnipptJSON={JSON.stringify(courseSnippt)}
        image={logo}
      />
      <ContactUsPopup />
      <Layout formLink="/marketing-launchpad-application-form/" fixAtTop={true}>
        <div id="placementhighlights">
          <HeroSection />
        </div>
        <TopCompanies />
        <StudentsBridge />
        <PickProgram />
        <KSLiveBanner />
        <div id="alumnitestimonials">
          <Feeds />
        </div>
        <Recruiters />
        <NewsCoverage />
        <KraftshalaWorks />
      </Layout>
    </div>
  );
}
