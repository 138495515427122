import csm from '../../../images/home/csm.webp';
import csmhover from '../../../images/home/csmhover.webp';
import paid from '../../../images/home/paid.webp';
import paidhover from '../../../images/home/paidhover.webp';

import basl from "../../../images/home/ecomm.webp";
import baslHover from "../../../images/home/ecommhover.webp";

export const courseData = [
  {
    course: 'PGP in Sales and Business Leadership',
    image:basl,
    imagehover:baslHover,
    title: 'Start your business leadership career in top companies in high potential roles of 7.5-17LPA. If the job < 7.5L, you get a 60% refund!',
    startAs:['Sales Managers / Executives','Business Development Executives','Key Account Managers / Executives'],
    link: '/sales-course/',
  },
  {
    course: 'Content And Social Media',
    image:csm,
    imagehover:csmhover,
    title: 'The place to get started in the Creative Marketing Industry! Create memes for brands, write blogs and get exposed to building a brand through content.',
    startAs:['Social Media Marketer','Content Writer', 'Influencer Marketing & more'],
    link: '/content-writing-course/',
  },
  {
    course: 'Digital Media Marketing',
    image:paid,
    imagehover:paidhover,
    title: 'Want to drive business results? Learn to run digital ads, create SEO strategies, manage E-com listings, and analytics, all to eventually drive revenue for great brands',
    startAs:['Digital Marketing Associate','Performance Marketer','SEO Executive','E-commerce Analyst & more'],
    link: '/marketing-launchpad/digital-marketing-course/',
  }
];
