import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./styles.scss";
import Marquee from "react-fast-marquee";
import LazyLoad from "react-lazyload";

export default function TopCompanies() {
  return (
    <div className="home-top-companies">
      <div className="mobile-hide">
        <div className="title">
        In the last 12 months, our students have been hired by the
        </div>
        <div className="sub-title">world’s best sales and marketing companies</div>
      </div>
      <div className="pc-hide">
        <div className="title">
        In the last 12 months, our students have been hired by the{" "}
          <span className="sub-title">world’s best sales and marketing companies</span>
        </div>
      </div>
      <LazyLoad>
        <div className="mobile-hide">
          <Marquee className="marquee">
            <StaticImage
              className="company"
              src="../../../images/home/companies/growisto.webp"
              alt="growisto"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/atomberg.webp"
              alt="atomberg"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/stage.webp"
              alt="atomberg"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/beacon.webp"
              alt="beacon"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/sesa.webp"
              alt="sesa"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/scaler.webp"
              alt="scaler"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/phisics.webp"
              alt="phisics"
            />
           
            <StaticImage
              className="company"
              src="../../../images/home/companies/schbang.webp"
              alt="schbang"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/coursera.webp"
              alt="coursera"
            />
          </Marquee>
          <Marquee className="marquee2">
            <StaticImage
              className="company"
              src="../../../images/home/companies/social.webp"
              alt="social"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/toothisi.webp"
              alt="toothisi"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/neeman.webp"
              alt="neeman"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/nyka.webp"
              alt="nyka"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/masai.webp"
              alt="masai"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/tata.webp"
              alt="tata"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/grpm.webp"
              alt="groupm"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/performatic.webp"
              alt="performatic"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/ant.webp"
              alt="ant"
            />
         
            <StaticImage
              className="company"
              src="../../../images/home/companies/pumpkin.webp"
              alt="pumpkin"
            />
            {/* <StaticImage
              className="company"
              src="../../../images/home/companies/publicis.webp"
              alt="publicis"
            /> */}
          </Marquee>
        </div>
      </LazyLoad>
      <LazyLoad>
        <div className="pc-none">
          <Marquee>
            <StaticImage
              className="company"
              src="../../../images/home/companies/growisto.webp"
              alt="growisto"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/atomberg.webp"
              alt="atomberg"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/beacon.webp"
              alt="beacon"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/sesa.webp"
              alt="sesa"
            />
          </Marquee>
          <Marquee>
              
            <StaticImage
              className="company"
              src="../../../images/home/companies/scaler.webp"
              alt="scaler"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/phisics.webp"
              alt="phisics"
            />
       
     
            <StaticImage
              className="company"
              src="../../../images/home/companies/schbang.webp"
              alt="schbang"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/coursera.webp"
              alt="coursera"
            />
          </Marquee>
          <Marquee>
            <StaticImage
              className="company"
              src="../../../images/home/companies/social.webp"
              alt="social"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/toothisi.webp"
              alt="toothisi"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/neeman.webp"
              alt="neeman"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/nyka.webp"
              alt="nyka"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/masai.webp"
              alt="masai"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/tata.webp"
              alt="tata"
            />
          </Marquee>
          <Marquee>
            <StaticImage
              className="company"
              src="../../../images/home/companies/grpm.webp"
              alt="groupm"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/performatic.webp"
              alt="performatic"
            />
            <StaticImage
              className="company"
              src="../../../images/home/companies/ant.webp"
              alt="ant"
            />
    
            <StaticImage
              className="company"
              src="../../../images/home/companies/pumpkin.webp"
              alt="pumpkin"
            />
            
            {/* <StaticImage
              className="company"
              src="../../../images/home/companies/publicis.webp"
              alt="publicis"
            /> */}
          </Marquee>
        </div>
      </LazyLoad>
    </div>
  );
}
