import prachi from '../../../images/home/alumnies/prachi.webp';
import atul from '../../../images/home/alumnies/atul.webp';
import gazal from '../../../images/home/alumnies/gazal.webp';
import sourav from '../../../images/home/alumnies/sourav.webp';
import charu from '../../../images/home/alumnies/charu.webp';
import kanika from '../../../images/home/alumnies/kanika.webp';
import dhara from '../../../images/home/alumnies/dhara.webp';
import nitish from '../../../images/home/alumnies/nitish.webp';
import nilta from '../../../images/home/alumnies/nilta.webp';
import dhruv from '../../../images/home/alumnies/dhruv.webp';
import Performics from '../../../images/home/companies/performatic.webp';
import nxtwave from '../../../images/home/companies/nxtwave_new.webp';
import adfactor from '../../../images/home/companies/adfactor_new.webp';
import whitegrape from '../../../images/home/companies/whitegrape.webp';
import extraedge from '../../../images/home/companies/extraedge_new.webp';
import souled from '../../../images/home/companies/souled.webp';
import masai from '../../../images/home/companies/masai.webp';
import sesa from '../../../images/home/companies/sesa.webp';
import sokrati from '../../../images/home/companies/sokrati_new.webp';
import toodle from '../../../images/home/companies/toddle_new.webp';

export const Data = [{
  name : 'Prachi Kothiyal',
  field: 'Electrical Engineer turned Marketer',
  company: Performics,
  image: prachi,
  description: 'Couldn’t convince my parents why I wanted this course, but they saw me change during the course. The learning from here does not end at placements, it goes way beyond that.'
},
{
  name : 'Atul Singh',
  field: 'From BBA to Content Writing',
  company: nxtwave,
  image: atul,
  description: 'Human Skills was worth it. It helped me not only during the course but also now in the job while working in a team and respecting everyone’s opinions.'
},
{
  name : 'Gazal Chawla',
  field: 'Student turned Digital Marketer',
  company: adfactor,
  image: gazal,
  description: 'I didn’t mind sitting throughout the day during the course because I loved what I did. It was rather a fun journey for me because of my batchmates!'
},
{
  name : 'Sourav Patra',
  field: 'Fashion influencer to handling 14 brands',
  company: whitegrape,
  image: sourav,
  description: 'I met my tribe here who helped me learn, grow and stay motivated throughout the course. And guess what that led to? Cracked a job in my first interview!'
},
{
  name : 'Charu Kulsreshtha',
  field: 'From Cabin Crew to Marketing',
  company: extraedge,
  image: charu,
  description: 'The training here was so much more practical and immersive than my aviation journey. Connecting with teammates, experts and getting feedback helped me get better.'
},
{
  name : 'Kanika Kashyap',
  field: 'From Hotel Management to Digital Marketing',
  company: toodle,
  image: kanika,
  description: 'Apart from the course being extensive that required me to put in a lot of effort, human skills helped me gain more confidence to perform and helped me present my CV better.'
},
{
  name : 'Dhara Kaji',
  field: 'From 0 experience to dream job',
  company: souled,
  image: dhara,
  description: 'Such a rich learning experience. Not just that, the course gave me an opportunity to be independent and do things on my own, exactly how I wanted!'
},
{
  name : 'Nitish Sonu',
  field: 'From Btech to Content Writing',
  company: masai,
  image:nitish,
  description: 'Human skills sessions have enabled me to deal with people in a way where from being a reserved person to now I do not think twice before getting out of my way to get things done. '
},
{
  name : 'Nilta Sood',
  field: 'CA turned Digital Marketer',
  company: sesa,
  image: nilta,
  description: 'The three months here made me so disciplined and ambitious about my career which 2020 Nilta couldn’t have even imagined herself to be. '
},
{
  name : 'Dhruv Patel',
  field: 'Computer Engineering to Client Servicing',
  company: sokrati,
  image: dhruv,
  description: 'We had incredible expert sessions with super-experienced people that helped me gain a lot of new perspectives. Can’t thank them enough!'
},

];