import aviral from "../../../images/common/recruiter/aviral.webp";
import shravini from "../../../images/common/recruiter/shravini.webp";
import sarung from "../../../images/common/recruiter/sarung.webp";
import suchit from "../../../images/common/recruiter/suchit.webp";
import Keyur from "../../BuisnessSalesLeadership/Assets/Keyur.webp";
import Rahul from "../../BuisnessSalesLeadership/Assets/Rahul.webp";

export const placementsData = [
  {
    image:
      aviral,
    name: "Aviral Chandra",
    company: "CEO and Founder, Sif Homes",
    description: `Kraftshala team deserves accolades for turning digital marketing enthusiasts into battle
     marketing professionals, ready for waging guerrilla marketing wars with their focused skills and market 
     readiness. We closed 2 positions and we feel we won’t need to train these guys as Kraftshala has already 
     done the hard work to that end. Kraftshala has my endorsement for hiring digital marketing professionals!
    `,
  },
  {
    image:
      suchit,
    name: "Suchit Sikaria",
    company: "Managing Partner, Performics India",
    description: `Performics India is extremely satisfied with our first experience with the Marketing Launchpad Program. First, it allows us to interact with students across different colleges & cities. Also saves a lot of effort on our end. Second and more importantly, we get a curated pool of talent with extremely relevant digital marketing training.
    We are optimistic about shifting a large part of our graduate hiring to the Launchpad program over the next year.`,
  },
  {
    image:
      sarung,
    name: "Sarung Subnani",
    company: "Senior Manager, Talent Acquisition, Splash Learn",
    description: `Kraftshala has truly positioned itself right, the syllabus is as apt as it can be and the quality
     of candidates was overwhelming to an extent that we made more offers than planned. The team working behind 
     the scene was true support right from detailing the candidates to understanding the role and pitching it well
      to the potential candidates. We certainly look forward to hiring from Kraftshala again.`,
  },
  {
    image:
      shravini,
    name: "Shravani Rangaswamy",
    company: "HR Analyst, Indigenesis Consulting Pvt. Ltd.",
    description: `Our association with Kraftshala, in my personal capacity, has been by far a positive one. 
    Their professionalism, quality, and response time are flawless. We were able to hire the right interns for
     our firm within a very short period of time. I strongly recommend Kraftshala for hiring marketing talent.
    `,
  },
  {
    image:Keyur,
    name: "Keyur Gupta",
    company: "Head - People Analytics and Projects, Nestle",
    description: `“Kraftshala provides a learning experience to students which is very relevant to them. They also provide students an opportunity to work on live projects where in addition to being taught by faculty, students also get mentored by business professionals who have seen the industry for a while. Kraftshala helps us build those competencies in marketing and sales folks who then understand the business as on the ground so they can hit the ground running when they join these corporates.”`,
  },
  {
    image:Rahul,
    name: "Rahul Sachdev",
    company: "Senior Director, Accessories, Myntra",
    description: `A big thanks to Kraftshala for unprecedented support in terms of their professionalism, their talent pool and a fast turnaround time. It gives me a lot of faith that they have the best selection criteria on campus given the kind of talent we had on the project. Not only did the candidates  have a basic appreciation of the industry, but were also behaviorally sound, given the fast paced research that was needed. Overall we are very delighted with the quality and the time in which the project was delivered and I would be more than willing to use this as a great route to hire talent in the future.`,
  },
];
